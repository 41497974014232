export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    orange: '#F06449',
    darkorange: '#ff4a28',
    darkblue: '#023A51',
    lightblue: '#D9E8ED',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'Work Sans, system-ui, sans-serif',
    serif: 'Playfair Display, serif',
  },
  shadows: {
    small: '0 2px 4px -2px rgba(0, 0, 0, 0.1)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    primary: {
      color: '#fff !important',
      backgroundColor: '#F06449',
      padding: '16px 24px',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'Work Sans, system-ui, sans-serif',
    },
    outline: {
      color: '#023A51',
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'Work Sans, system-ui, sans-serif',
    },
  },
}

import React, { useState } from 'react'
import { Link } from 'gatsby'
import { IconContext } from 'react-icons'
import { FiMenu, FiX } from 'react-icons/fi'
import styled, { withTheme } from 'styled-components'
import posed from 'react-pose'

const Animation = posed.ul({
  hidden: {
    height: 0,
    transition: { ease: 'backOut' },
  },
  visible: {
    height: 'auto',
    transition: { ease: 'easeIn' },
  },
})

const Menu = styled(Animation)`
  width: 100%;
  background: white;
  list-style: none;
  position: absolute;
  top: 80px;
  left: 0;
  margin: 0;
  padding: 0;
  height: auto;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows.small};
  overflow: hidden;

  li {
    font-family: ${({ theme }) => theme.fonts.sans};
    color: ${({ theme }) => theme.colors.darkblue};
    cursor: pointer;
    text-align: left;
    padding: ${({ theme }) => `${theme.space[3]}px`};

    &:last-child {
      padding-bottom: ${({ theme }) => `${theme.space[4]}px`};
    }
  }
`

const BurgerLink = ({ to, children, theme }) => (
  <Link
    activeStyle={{
      color: theme.colors.orange,
      fontWeight: 'bold',
    }}
    to={to}
    partiallyActive={true}
  >
    {children}
  </Link>
)

const BurgerMenu = ({ theme }) => {
  const [isOpen, setOpen] = useState(false)

  const renderIcon = () => (
    <IconContext.Provider
      value={{ size: 32, color: '#12283A', style: { cursor: 'pointer' } }}
    >
      {isOpen ? (
        <FiX onClick={() => setOpen(false)} />
      ) : (
        <FiMenu onClick={() => setOpen(true)} />
      )}
    </IconContext.Provider>
  )

  return (
    <>
      {renderIcon()}
      <Menu theme={theme} isOpen={isOpen} pose={isOpen ? 'visible' : 'hidden'}>
        <li>
          <BurgerLink to="/formazione" theme={theme}>
            Formazione
          </BurgerLink>
        </li>
        <li>
          <BurgerLink to="/servizi" theme={theme}>
            Servizi
          </BurgerLink>
        </li>
        <li>
          <BurgerLink to="/chi-siamo" theme={theme}>
            Chi siamo
          </BurgerLink>
        </li>
        <li>
          <BurgerLink to="/contattaci" theme={theme}>
            Contattaci
          </BurgerLink>
        </li>
      </Menu>
    </>
  )
}

export default withTheme(BurgerMenu)

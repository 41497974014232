import React from 'react'
import MediaQuery from 'react-responsive'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'
import { Text } from 'rebass'

import BurgerMenu from './burgermenu'

const NavigationLink = ({ theme, to, children }) => (
  <Text
    fontFamily={theme.fonts.sans}
    ml={theme.space[4]}
    color={theme.colors.darkblue}
  >
    <Link
      to={to}
      activeStyle={{
        color: theme.colors.orange,
        paddingBottom: '2px',
        borderBottom: `1px solid ${theme.colors.orange}`,
      }}
      partiallyActive={true}
    >
      {children}
    </Link>
  </Text>
)

const DesktopMenu = ({ theme }) => (
  <>
    <NavigationLink theme={theme} to="/formazione">
      Formazione
    </NavigationLink>
    <NavigationLink theme={theme} to="/servizi">
      Servizi
    </NavigationLink>
    <NavigationLink theme={theme} to="/chi-siamo">
      Chi siamo
    </NavigationLink>
    <NavigationLink theme={theme} to="/contattaci">
      Contattaci
    </NavigationLink>
  </>
)

const Navigation = ({ theme }) => (
  <MediaQuery maxWidth={theme.breakpoints[1]}>
    {matches =>
      matches ? <BurgerMenu theme={theme} /> : <DesktopMenu theme={theme} />
    }
  </MediaQuery>
)

export default withTheme(Navigation)

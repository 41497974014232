import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Image } from 'rebass'
import { withTheme } from 'styled-components'

import Navigation from './navigation'

const LogoQuery = graphql`
  {
    file(relativePath: { eq: "logo.svg" }) {
      relativePath
      name
      publicURL
    }
  }
`

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={LogoQuery}
    render={data => (
      <Flex alignItems="center" p={3} bg="white">
        <Link to="/">
          <Image
            width={[258, 258, 258]}
            src={data.file.publicURL}
            alt={siteTitle}
          />
        </Link>
        <Box mx="auto" />
        <Navigation />
      </Flex>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTheme(Header)

import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import { Flex, Box, Image, Text, Heading } from 'rebass'

const Link = styled(GatsbyLink)`
  color: #d9e8ed;
  display: block;
  margin-bottom: 8px;
  opacity: 0.6;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }

  &:visited {
    color: #d9e8ed;
  }
`

const Footer = ({ theme }) => {
  const data = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "logo_white.svg" }) {
          relativePath
          name
          publicURL
        }
        links: allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                order
              }
            }
          }
        }
      }
    `
  )

  const services = data.links.edges
    .filter(page => page.node.fields.slug.includes('/servizi/'))
    .sort((el1, el2) => el1.node.frontmatter.order - el2.node.frontmatter.order)

  const courses = data.links.edges
    .filter(page => page.node.fields.slug.includes('/formazione/'))
    .sort((el1, el2) => el1.node.frontmatter.order - el2.node.frontmatter.order)

  return (
    <>
      <Flex
        bg={theme.colors.darkblue}
        mb={0}
        color={theme.colors.lightblue}
        p={3}
        py={[4, 3, 3]}
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box width={[1, 1, 1 / 4]} my={[2, 2, 0]} p={[0, 1, 1]}>
          <Image
            width={[258, 258, 258]}
            mx={['auto', 'auto', 'inherit']}
            src={data.logo.publicURL}
            css={{ display: 'block' }}
          />
        </Box>
        <Box width={[1, 1, 1 / 4]} mt={[4, 4, 0]} py={[1, 3, 3]} px={[0, 3, 3]}>
          <Heading fontSize={3} color="#5790A2" fontWeight="500" mb={3}>
            Formazione
          </Heading>
          {courses.map(course => (
            <Link to={course.node.fields.slug} key={course.node.fields.slug}>
              {course.node.frontmatter.title}
            </Link>
          ))}
        </Box>
        <Box width={[1, 1, 1 / 4]} mt={[4, 4, 0]} py={[1, 3, 3]} px={[0, 3, 3]}>
          <Heading fontSize={3} color="#5790A2" fontWeight="500" mb={3}>
            Servizi
          </Heading>
          {services.map(service => (
            <Link to={service.node.fields.slug} key={service.node.fields.slug}>
              {service.node.frontmatter.title}
            </Link>
          ))}
        </Box>
        <Box width={[1, 1, 1 / 4]} mt={[4, 4, 0]} py={[1, 3, 3]} px={[0, 3, 3]}>
          <Heading fontSize={3} color="#5790A2" fontWeight="500" mb={3}>
            Generali
          </Heading>
          <Link to="/contattaci">Contattaci</Link>
          <Link to="/chi-siamo">Chi siamo</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Box>
      </Flex>
      <Box bg={theme.colors.darkblue} mb={0} p={4}>
        <Text color="#376578" textAlign="center">
          © {new Date().getFullYear()} - Safety Advisors S.R.L. - P. IVA:
          IT11879220017
        </Text>
      </Box>
    </>
  )
}

export default withTheme(Footer)
